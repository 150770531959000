import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import useComponentFactory from '../hooks/useComponentFactory';
import PageDataContext from '../state/PageDataContext';
import Seo from '../components/Seo/Seo';
import ContentfulPageHeader from '../components/ContentfulPageHeader/ContentfulPageHeader';
import PageTransition from 'gatsby-plugin-page-transitions';

const GenericPageTemplate = ({ data, location, ...rest }) => {
  const pageData = data.contentfulPages;
  const components = useComponentFactory(pageData.components);
  return (
    <PageTransition>
      <PageDataContext.Provider value={{ ...pageData, ...rest, location }}>
        <Seo
          contentTitle={pageData.title}
          pathname={location.pathname}
          seo={pageData.seo}
        />
        <Layout
          {...(pageData?.pageThemeColour && {
            style: { '--theme-accent-color': `#${pageData?.pageThemeColour}` },
          })}>
          {pageData?.pageHeader && (
            <ContentfulPageHeader {...pageData.pageHeader} />
          )}
          {components}
        </Layout>
      </PageDataContext.Provider>
    </PageTransition>
  );
};

export const pageQuery = graphql`
  query GenericPageById($id: String!) {
    contentfulPages(id: { eq: $id }) {
      id
      title
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
        hidePageFromSearchEngines
        excludeFromSitemap
      }
      pageHeader {
        title
        description {
          description
        }
        fullWidthHeading
        textColour
        image {
          file {
            url
          }
          gatsbyImageData
          title
        }
      }
      heroImage {
        gatsbyImageData
        title
        file {
          url
        }
      }
      pageThemeColour
      description {
        raw
      }
      components {
        __typename

        ... on ContentfulAward {
          ...ContentfulAwardFields
        }
        ... on ContentfulComponentFullWidthImage {
          ...ContentfulComponentFullWidthImageFields
        }
        ... on ContentfulComponentGetInTouch {
          ...ContentfulComponentGetInTouchFields
        }
        ... on ContentfulComponentImageCarousel {
          ...ContentfulComponentImageCarouselFields
        }
        ... on ContentfulComponentPageHeader {
          ...ContentfulComponentPageHeaderFields
        }
        ... on ContentfulComponentQuote {
          ...ContentfulComponentQuoteFields
        }
        ... on ContentfulComponentShareStory {
          ...ContentfulComponentShareStoryFields
        }
        ... on ContentfulComponentProjectSpotlight {
          ...ContentfulComponentProjectSpotlightFields
        }
        ... on ContentfulComponentStats {
          ...ContentfulComponentStatsFields
        }
        ... on ContentfulComponentText {
          ...ContentfulComponentTextFields
        }
        ... on ContentfulComponentVideo {
          ...ContentfulComponentVideoFields
        }
        ... on ContentfulComponentHeading {
          ...ContentfulComponentHeadingFields
        }
        ... on ContentfulComponentTwoColumnImageAndDescription {
          ...ContentfulComponentTwoColumnImageAndDescriptionFields
        }
        ... on ContentfulComponentAwards {
          ...ContentfulComponentAwardsFields
        }
        ... on ContentfulComponentLottieAnimation {
          ...ContentfulComponentLottieAnimationFields
        }
        ... on ContentfulComponentCallOut {
          ...ContentfulComponentCallOutFields
        }
        ... on ContentfulComponentClientGrid {
          ...ContentfulComponentClientGrid
        }
        ... on ContentfulComponentEmbed {
          ...ContentfulComponentEmbedFields
        }
        ... on ContentfulComponentCta {
          ...ContentfulComponentCtaFields
        }
        ... on ContentfulComponentContentSpotlight {
          ...ContentfulComponentContentSpotlight
        }
        ... on ContentfulComponentRelatedContent {
          ...ContentfulComponentRelatedContentPagesFields
        }
        ... on ContentfulComponentClientMissionsCarousel {
          ...ContentfulComponentClientMissionsCarouselFields
        }
        ... on ContentfulComponentAnimatedHeading {
          ...ContentfulComponentAnimatedHeadingFields
        }
        ... on ContentfulComponentThreeColumnContent {
          ...ContentfulComponentThreeColumnContentFields
        }
      }
    }
  }
`;

export default GenericPageTemplate;
